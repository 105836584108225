rodijco = angular.module 'rodijco', [
  'ngRoute'
  'ngSanitize'
  'httpService'
  'angucomplete-alt'
  # 'ui.materialize'
]

views = '/js/app/views/'
rootPath = '/api/'
DIALOG_DURATION = 2000

rodijco.config ($routeProvider, $locationProvider) ->
  $routeProvider

  .when('/download-latest',
      controller: 'BufferController'
      templateUrl: "#{views}buffer/index.html")

  # Customer certificates
  .when('/certificates-customer/:group?',
      controller: 'CertificatesCustomerController'
      templateUrl: "#{views}certificates/customer.html")

  # Certificates
  .when('/certificates/:group?',
      controller: 'CertificatesController'
      templateUrl: "#{views}certificates/index.html")

  .when('/certificates/group/:id',
      controller: 'CertificatesGroupFormController'
      templateUrl: "#{views}certificates/group-form.html")

  .when('/certificates/:group/:id',
      controller: 'CertificatesFormController'
      templateUrl: "#{views}certificates/form.html")

  # Logs
  .when('/logs',
      controller: 'LogsController'
      templateUrl: "#{views}logs/index.html")

  # Products
  .when('/products/:group?',
      controller: 'ProductsController'
      templateUrl: "#{views}products/index.html")

  .when('/products/group/:id',
      controller: 'ProductsGroupFormController'
      templateUrl: "#{views}products/group-form.html")

  .when('/products/:group/:id',
      controller: 'ProductsFormController'
      templateUrl: "#{views}products/form.html")

  # Customers
  .when('/customers',
      controller: 'CustomersController'
      templateUrl: "#{views}customers/index.html")

  .when('/customers/:id',
      controller: 'CustomersUpsertController'
      templateUrl: "#{views}customers/upsert.html")

  # Settings
  .when('/settings',
      controller: 'SettingsController'
      templateUrl: "#{views}settings/index.html")

  .when('/settings/change-password',
      controller: 'SettingsChangePasswordController'
      templateUrl: "#{views}settings/change-password.html")

  # admin
  .when('/admin',
      controller: 'ConfigController'
      templateUrl: "#{views}config/index.html")

  # Login
  .when('/login',
    controller: 'LoginController'
    templateUrl: "#{views}login/form.html")

  .otherwise redirectTo: '/'

  $locationProvider.html5Mode true
